import React from 'react';
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
} from '@material-ui/core';
import { AttachFile, PictureAsPdf, Delete, FileCopy } from '@material-ui/icons';
import { ISolicitacaoArquivo } from '../../../models';
import useLogin from '../../../hooks/useLogin';
import styles from './styles';

interface Props {
  solicitacaoId: string;
  requestFile: ISolicitacaoArquivo[];
  onDelete: (id: string) => void;
}

const ImageListDocuments: React.FC<Props> = ({
  solicitacaoId,
  requestFile,
  onDelete,
}) => {
  const classes = styles();
  const { userSelector } = useLogin();

  const iconInfo = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const iconsDocuments = (solicitacaoArquivo: ISolicitacaoArquivo) => {
    if (solicitacaoArquivo.tipo === 'application/pdf') {
      return (
        <div className={classes.divDocument}>
          <PictureAsPdf className={classes.iconDocument} />
        </div>
      );
    }

    if (solicitacaoArquivo.tipo.split('/')[0] === 'image') {
      return (
        <img
          style={{ maxHeight: '100%', maxWidth: '100%' }}
          src={`${process.env.REACT_APP_BASE_URL}/${solicitacaoArquivo.filename}`}
          alt={solicitacaoArquivo.nome}
        />
      );
    }

    return (
      <div className={classes.divDocument}>
        <AttachFile className={classes.iconDocument} />
      </div>
    );
  };

  return (
    <div className={classes.list}>
      <ImageList className={classes.imageList} cols={5}>
        {requestFile.map((item: ISolicitacaoArquivo) => (
          <ImageListItem
            key={item.id}
            style={{ minHeight: 100, minWidth: 200 }}
          >
            {iconsDocuments(item)}

            <ImageListItemBar
              key={item.id}
              title={item.nome}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              actionIcon={
                <>
                  <Tooltip title="Visualizar">
                    <IconButton
                      aria-label="Visualizar"
                      className={classes.iconList}
                      onClick={() =>
                        iconInfo(
                          `${process.env.REACT_APP_BASE_URL}/${item.filename}`
                        )
                      }
                    >
                      <FileCopy style={{ fontSize: 16 }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Remover Arquivo">
                    <IconButton
                      aria-label="Remover"
                      style={{ color: '#800000' }}
                      onClick={() => onDelete(item.id)}
                    >
                      <Delete style={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default ImageListDocuments;
