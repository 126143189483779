import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CssBaseline from '@material-ui/core/CssBaseline';
import ContainerUI from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useHistory } from 'react-router-dom';
import TextField from '../../components/TextField';
import PasswordField from '../../components/PasswordField';
import Button from '../../components/Button';
import useLogin from '../../hooks/useLogin';
import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://opcaovirtual.com.br/">
        Opção Virtual Tecnologia e Serviços
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { onLogin, clearState, userSelector } = useLogin();
  const [alert, setAlert] = useState(false);
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({});

  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/dashboard');
    }

    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (userSelector.isError) {
      setAlert(true);
    }

    if (userSelector.isSuccess) {
      dispatch(clearState());
      history.push('/dashboard');
    }
  }, [userSelector.isError, userSelector.isSuccess]);

  const onSubmit = async (formData: any) => {
    setAlert(false);
    dispatch(clearState());

    await onLogin(formData.user, formData.password).then(() => {
      if (userSelector.isError) {
        setAlert(true);
      }
    });
  };

  return (
    <ContainerUI component="main" maxWidth="xs">
      <CssBaseline />
      <Container>
        <div className="paper">
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Help Desk - Acesso
          </Typography>

          {alert && userSelector.isError ? (
            <Alert
              className="alert"
              variant="outlined"
              severity="error"
              onClose={() => {
                setAlert(false);
              }}
            >
              {userSelector.errorMessage}
            </Alert>
          ) : undefined}

          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="user"
              name="user"
              control={control}
              defaultValue=""
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Usuário"
              autoComplete="user"
              autoFocus
            />

            <PasswordField
              id="password"
              name="password"
              control={control}
              defaultValue=""
              variant="outlined"
              required
              fullWidth
              label="Senha"
              type="password"
              autoComplete="current-password"
            />

            <Button
              fullWidth
              caption="Entrar"
              className="button"
              type="submit"
              loading={userSelector.isFetching}
            />
          </form>
        </div>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </ContainerUI>
  );
};

export default Login;
