import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  TextField as TextFieldCore,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Clear, VisibilityOutlined } from '@material-ui/icons';
import useUtility from '../../hooks/useUtility';
import api from '../../services/api';
import Page from '../../components/Page';
import snack from '../../components/SnackbarUtilsConfigurator';
import MaterialTableX from '../../components/MaterialTable';
import DialogForm from '../../components/DialogForm';
import Dialog from '../../components/Dialog';
import TextField from '../../components/TextField';
import { IDepartamento, IFilial, IFilialDepartamento } from '../../models';
import styles from './styles';

const schema = yup
  .object()
  .shape({
    razaoSocial: yup.string().required('Este campo é obrigatório'),
    cpfCnpj: yup
      .string()
      .trim()
      .matches(
        /(^\d{3}\.\d{3}.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
        'CPF/CNPJ inválido'
      )
      .required('Este campo é obrigatório'),
  })
  .required();

const Branch = () => {
  const history = useHistory();
  const classes = styles();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDep, setIsLoadingDep] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector } = useUtility();
  const [data, setData] = useState<IFilial[]>([]);
  const [branch, setBranch] = useState<IFilial>();
  const [department, setDepartment] = useState<IDepartamento[]>([]);
  const [openDep, setOpenDep] = useState(false);
  const [dep, setDep] = useState<IFilialDepartamento[]>([]);

  const { control, handleSubmit, reset } = useForm<IFilial>({
    resolver: yupResolver(schema),
  });

  const actions = [
    {
      icon: () => <Clear />,
      tooltip: 'Excluir',
      isFreeAction: false,
      onClick: (event: React.MouseEvent, rowData: any) => {
        deleteBranchDep(rowData.id);
      },
    },
  ];

  const columns = [
    { title: 'Id', field: 'id', hidden: true },
    {
      title: 'Razão Social',
      field: 'razaoSocial',
      hidden: false,
      cellStyle: {
        width: '38%',
      },
    },
    {
      title: 'Nome Fantasia',
      field: 'nomeFantasia',
      hidden: false,
      cellStyle: {
        width: '33%',
      },
    },
    {
      title: 'Departamentos',
      field: 'departamentos',
      hidden: false,
      searchable: false,
      sorting: false,
      render: (rowData: IFilial) => (
        <div className={classes.divDocument}>
          {rowData.departamentos?.toString().padStart(3, '0')}
          <IconButton
            onClick={() => {
              if (!rowData.acessoDepSelec) {
                snack.warning(
                  'Não é permitido vincular departamentos quando a empresa tem permissão de acessar todos os departamentos'
                );
                return;
              }

              setBranch(rowData);
              branchChildrenDep(rowData.id);
            }}
            size="small"
          >
            <Tooltip title="Visualizar Departamentos" placement="bottom">
              <VisibilityOutlined fontSize="small" />
            </Tooltip>
          </IconButton>
        </div>
      ),
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Acessa Dep. Selec.',
      field: 'acessoDepSelec',
      hidden: false,
      searchable: false,
      render: (rowData: IFilial) => (
        <div className={classes.divDocument}>
          <Chip
            className={clsx(
              classes.active,
              !rowData.acessoDepSelec && classes.inactive
            )}
            variant="outlined"
            size="small"
            label={rowData.acessoDepSelec ? 'Sim' : 'Não'}
          />
        </div>
      ),
      cellStyle: {
        width: '12%',
      },
    },
    {
      title: 'Ativo',
      field: 'ativo',
      hidden: false,
      searchable: false,
      render: (rowData: IFilial) => (
        <Chip
          className={clsx(classes.active, !rowData.ativo && classes.inactive)}
          variant="outlined"
          size="small"
          label={rowData.ativo ? 'Sim' : 'Não'}
        />
      ),
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Automático',
      field: 'automatico',
      hidden: false,
      searchable: false,
      render: (rowData: IFilial) => (
        <div className={classes.divDocument}>
          <Chip
            className={clsx(
              classes.active,
              !rowData.automatico && classes.inactive
            )}
            variant="outlined"
            size="small"
            label={rowData.automatico ? 'Sim' : 'Não'}
          />
        </div>
      ),
      cellStyle: {
        width: '7%',
      },
    },
  ];

  const columnsDep = [
    { title: 'Descrição', field: 'descricaoDepartamento', hidden: false },
  ];

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    }

    getBranches();
  }, []);

  useEffect(() => {
    async function fetchBranch() {
      await api
        .get<IFilial>(`api/Filial/Get/${utilitySelector.dialogId}`)
        .then(result => {
          reset(result.data);
          setIsFetch(false);
        })
        .catch(() => {
          setIsFetch(false);
        });
    }

    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setIsFetch(true);
        fetchBranch();
      }
    }
  }, [utilitySelector.dialogOpen]);

  const getBranches = async () => {
    setIsLoading(true);

    await api
      .get<IFilial[]>('/api/Filial/GetAll')
      .then(result => {
        setData(result.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getDepartments = async () => {
    await api
      .get(`/api/Departamento/GetAll/?ativo=${true}&selec=${true}`)
      .then(result => {
        setDepartment(result.data);
      })
      .catch(() => {
        setIsLoadingDep(false);
      });
  };

  const getBranchDepartments = async (id: string) => {
    await api
      .get(`/api/FilialDepartamento/GetAll/${id}`)
      .then(result => {
        setDep(result.data);
        setIsLoadingDep(false);
      })
      .catch(() => {
        setIsLoadingDep(false);
      });
  };

  const createBranch = async (fil: IFilial) => {
    await api.post<IFilial>(`api/Filial/Create`, fil).then(result => {
      setData([...data, result.data]);
    });
  };

  const updateBranch = async (fil: IFilial) => {
    await api.put<IFilial>(`api/Filial/Edit`, fil).then(result => {
      const updateTable = [...data];
      const index = updateTable.findIndex(x => x.id === fil.id);
      updateTable[index] = result.data;
      setData(updateTable);
    });
  };

  const activeBranch = async (fil: IFilial) => {
    fil.cpfCnpj = fil.cpfCnpj.replace(/[^0-9]/g, '');
    fil.ativo = !fil.ativo;
    await updateBranch(fil);
  };

  const onSubmit = async (formData: IFilial) => {
    formData.cpfCnpj = formData.cpfCnpj.replace(/[^0-9]/g, '');

    if (utilitySelector.dialogId) {
      await updateBranch(formData);
    } else {
      await createBranch(formData);
    }

    dispatch(closeDialog());
    reset({});
  };

  const handleClose = () => {
    dispatch(closeDialog());
    reset({});
  };

  const branchChildrenDep = (id: string) => {
    setIsLoadingDep(true);

    getDepartments();
    getBranchDepartments(id);
    setOpenDep(true);
  };

  const handleCloseDep = () => {
    setOpenDep(false);
    setDep([]);
  };

  const createBranchDep = async (filDep: IFilialDepartamento[]) => {
    await api
      .post<IFilialDepartamento[]>(
        `api/FilialDepartamento/Create/${branch?.id}`,
        filDep
      )
      .then(result => {
        const updateTable = [...data];
        const index = updateTable.findIndex(x => x.id === branch?.id);
        updateTable[index].departamentos = result.data.length;
        setData(updateTable);
      });
  };

  const onSubmitDep = async () => {
    createBranchDep(dep);
    setOpenDep(false);
  };

  const deleteBranchDep = async (id: string) => {
    const updateTable = [...dep];
    const index = updateTable.findIndex(x => x.id === id);
    updateTable.splice(index, 1);
    setDep(updateTable);
  };

  const onChange = (event: any, value: any) => {
    if (value) {
      const updateTable = [...dep];
      const index = updateTable.findIndex(x => x.departamentoId === value.id);

      if (index >= 0) {
        updateTable[index].departamentoId = value.id;
        updateTable[index].filialId = branch?.id || '';
        setDep(updateTable);
      } else {
        const obj = {
          departamentoId: value.id,
          filialId: branch?.id,
          descricaoDepartamento: value.descricao,
        };

        setDep([...dep, obj as IFilialDepartamento]);
      }
    }
  };

  return (
    <Page>
      <MaterialTableX
        title={<h2>Filiais ({data.length})</h2>}
        columns={columns}
        data={data}
        isLoading={isLoading}
        onRefresh={getBranches}
        onActive={activeBranch}
        detailPanel={(rowData: IFilial) => {
          return (
            <Grid container spacing={2} className={classes.grid}>
              <Grid item md={6} xs={6}>
                <Typography component="span" variant="subtitle2">
                  CPF/CNPJ: {rowData.cpfCnpj}
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography component="span" variant="subtitle2">
                  Inscrição Estadual: {rowData.inscEstadual}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <DialogForm
        titleDiag="Filial"
        subTitle="Informações da Filial"
        open={utilitySelector.dialogOpen}
        isLoading={isFetch}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          name="razaoSocial"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Razão Social"
          autoFocus
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          name="nomeFantasia"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Nome Fantasia"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          name="cpfCnpj"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="CPF/CNPJ"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          name="inscEstadual"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Inscrição Estadual"
          InputLabelProps={{ shrink: true }}
        />

        <FormControlLabel
          className={classes.formControlChecked}
          control={
            <Controller
              name="acessoDepSelec"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                />
              )}
            />
          }
          label="Acessa Apenas Departamentos Selecionados"
        />

        <FormControlLabel
          className={classes.formControlChecked}
          control={
            <Controller
              name="ativo"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                />
              )}
            />
          }
          label="Ativo"
        />
      </DialogForm>
      <Dialog
        titleDiag={`Filial: ${branch?.razaoSocial}`}
        captionOk="Salvar"
        open={openDep}
        maxWidth="md"
        handleClose={handleCloseDep}
        onOk={onSubmitDep}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Autocomplete
              options={department}
              getOptionLabel={(option: IDepartamento) => option.descricao}
              style={{ marginTop: 23 }}
              noOptionsText="Sem opções"
              openText="Abrir"
              clearText="Limpar"
              clearOnEscape
              renderInput={params => (
                <TextFieldCore
                  {...params}
                  label="Adicionar Departamento"
                  variant="outlined"
                  autoFocus
                  fullWidth
                />
              )}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <MaterialTableX
              title="Departamentos"
              actionsOverride={actions}
              columns={columnsDep}
              data={dep}
              isLoading={isLoadingDep}
              onDelete={deleteBranchDep}
              style={{ marginTop: 20 }}
              hideColumnsButton
              hideExportButton
              isRightSearch
            />
          </Grid>
        </Grid>
      </Dialog>
    </Page>
  );
};

export default Branch;
