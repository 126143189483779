import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions, makeStyles } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '../Button';
import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  actionButton: {
    width: 100,
    margin: theme.spacing(3, 0.5, 2),
  },
}));
interface Props {
  titleDiag: string;
  subTitle?: string;
  handleClose: () => void;
  onOk?: () => void;
  captionOk?: string;
  captionCancel?: string;
}

const Dialog: React.FC<DialogProps & Props> = ({
  titleDiag,
  subTitle,
  handleClose,
  onOk,
  captionOk,
  captionCancel,
  children,
  ...dialogProps
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      handleClose();
    }
  };

  return (
    <Container
      {...dialogProps}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{titleDiag}</DialogTitle>
      <DialogContent>
        {subTitle && <DialogContentText>{subTitle}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {onOk && (
          <Button
            className={classes.actionButton}
            caption={captionOk || 'Salvar'}
            onClick={onOk}
          />
        )}
        <Button
          className={classes.actionButton}
          caption={captionCancel || 'Cancelar'}
          onClick={handleClose}
        />
      </DialogActions>
    </Container>
  );
};

export default Dialog;
