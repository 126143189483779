import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { Controller, UseControllerProps } from 'react-hook-form';
import { TextFieldStyled } from './styles';

const TextField: React.FC<TextFieldProps & UseControllerProps<any>> = ({
  name,
  control,
  rules,
  defaultValue,
  ...textFieldProps
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <TextFieldStyled
        {...field}
        {...textFieldProps}
        margin="normal"
        onChange={field.onChange}
        value={field.value || ''}
        error={!!error}
        helperText={error ? error.message : null}
        autoComplete="new-password"
        ref={field.ref}
      />
    )}
  />
);

export default TextField;
