import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
  },
  active: {
    color: '#ffffff',
    borderColor: '#009900',
    backgroundColor: '#009900',
  },
  inactive: {
    color: '#ffffff',
    borderColor: '#ff1a1a',
    backgroundColor: '#ff1a1a',
  },
  selectControl: {
    height: 55,
  },
  submitBtn: {
    width: 100,
    margin: theme.spacing(3, 0.5, 2),
  },
  optionsControl: {
    float: 'right',
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  formControlChecked: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  textError: {
    color: '#ff1a1a',
  },
  divDocument: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {
    backgroundColor: '#FFFFFF',
    padding: 10,
  },
  autoComplete: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
